<template>
    <painel titulo="Parâmetros" icone="pi pi-copy" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-4">
                <label>Código</label>
                <InputText type="text" v-model="codigo" :disabled="desabilitaForm" @input="v$.codigo.$touch()" />
                <small class="p-error" v-if="v$.codigo.$error">O código é obrigatório</small>
            </div>
            <div class="field col-12 md:col-8">
                <label>Descrição</label>
                <InputText type="text" v-model="descricao" :disabled="desabilitaForm" @input="v$.descricao.$touch()" />
                <small class="p-error" v-if="v$.descricao.$error">A descrição é obrigatória</small>
            </div>
            <div class="field col-12">
                <label>Detalhe</label>
                <InputText type="text" v-model="detalhe" :disabled="desabilitaForm" @input="v$.detalhe.$touch()" />
                <small class="p-error" v-if="v$.detalhe.$error">O detalhe é obrigatório</small>
            </div>
            <div class="field col-12">
                <label>Valor</label>
                <Textarea v-model="valor" rows="20" :disabled="desabilitaForm" @input="v$.valor.$touch()" />
                <small class="p-error" v-if="v$.valor.$error">O valor é obrigatório</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" v-if="!desabilitaForm" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        parametro: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            codigo: null,
            descricao: null,
            valor: null,
            detalhe: null,
        };
    },

    validations() {
        return {
            codigo: { required },
            descricao: { required },
            valor: { required },
            detalhe: { required },
        };
    },

    methods: {
        preencher() {
            this.codigo = this.parametro?.codigo;
            this.descricao = this.parametro?.descricao;
            this.valor = this.parametro?.valor;
            this.detalhe = this.parametro?.detalhe;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o parâmetro?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let parametroDto = {
                codigo: this.codigo,
                descricao: this.descricao,
                valor: this.valor,
                detalhe: this.detalhe,
            };
            this.$emit('salvar', parametroDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },
    },

    watch: {
        parametro() {
            this.preencher();
        },
    },
};
</script>
