<template>
    <parametro-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></parametro-form>
</template>

<script>
import ParametroForm from './ParametroForm';
import ParametrosService from './services';

export default {
    components: {
        ParametroForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    computed: {},

    methods: {
        cancelar() {
            this.$router.push({ name: `Parametros` });
        },

        inserir(parametroInserido) {
            this.$store.dispatch('addRequest');
            let parametroInseridoDto = {
                codigo: parametroInserido.codigo,
                descricao: parametroInserido.descricao,
                valor: parametroInserido.valor,
                detalhe: parametroInserido.detalhe,
            };
            ParametrosService.inserir(parametroInseridoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Parâmetros',
                        detail: 'Parâmetro inserido com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(parametroId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: `Parametros_Detalhar`,
                params: {
                    id: parametroId,
                },
            });
        },
    },
};
</script>
